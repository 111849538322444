import { colors, Stack } from "@biblioteksentralen/js-utils";
import { Box, Text, VStack } from "@chakra-ui/layout";
import React from "react";
import SocialMediaView from "../../contactInfo/SocialMedia";
import { HeaderFooterContainer } from "../../ContentContainer";
import { FooterMenu } from "./FooterMenu";
import { useCommonData } from "../CommonDataProvider";
import Coatofarms from "./CoatOfArms";
import DevKnapper from "./DevKnapper";
import LibryContentLinks from "./LibryContentLinks";
import { useTranslation } from "../../../utils/hooks/useTranslation";
import { useSitePalette } from "../../../utils/useSitePalette";

function Footer() {
  const { site } = useCommonData();
  const { t } = useTranslation();
  const palette = useSitePalette();

  return (
    <footer>
      <Box minHeight="20rem" {...palette.colors.footer.css}>
        <HeaderFooterContainer
          padding={{ base: "3rem 1rem 1.5rem", md: "3rem 1rem" }}
          minHeight="100%"
          display="flex"
          gridGap="1rem"
          flexDirection="column"
          justifyContent="flex-end"
        >
          <DevKnapper />
          <FooterMenu />
          <VStack align="start">
            <Stack
              flexDirection={{ base: "column", md: "row" }}
              justifyContent="space-between"
              alignItems={{ base: undefined, md: "center" }}
              spacing="1rem"
              width="100%"
            >
              <Stack
                flexDirection={{ base: "column", md: "row" }}
                alignItems={{ base: undefined, md: "center" }}
                flexWrap="wrap"
                gridGap="1rem"
                width={{ base: "100%" }}
                borderColor={{ base: colors.grey45, md: colors.black }}
              >
                <SocialMediaView
                  socialMedia={site?.socialMedia}
                  borderBottom="0.1rem solid"
                  borderColor={{ base: colors.grey45, md: colors.black }}
                  paddingBottom={{ base: "1rem", md: "0" }}
                />
                {site?.editorName && (
                  <Box
                    fontSize={{ base: "sm", md: "md" }}
                    marginTop="0 !important"
                    padding={{ base: "0.5rem 0", md: "0" }}
                    borderBottom="0.1rem solid"
                    borderColor={{ base: colors.grey45, md: colors.black }}
                    paddingBottom={{ base: "1.5rem", md: "0" }}
                  >
                    {t("Ansvarlig redaktør")}
                    {": "}
                    <Text as="span" translate="no">
                      {site?.editorName}
                    </Text>
                  </Box>
                )}
              </Stack>
              <Box flexShrink={0} marginTop={{ base: "1.5rem !important", md: "0 !important" }}>
                <Coatofarms />
              </Box>
            </Stack>
          </VStack>
        </HeaderFooterContainer>
      </Box>
      <LibryContentLinks />
    </footer>
  );
}

export default Footer;
